@import '../../styles/breakpoints';
@import '../../styles/colors';
$footer-line-space: 32px;

footer {
  background-color: $accent-grey;
  flex: 1 1 0;
  flex-shrink: 0;

  .footer__copyright {
    display: inline;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    &-mobile {
      display: none;
      @include media-breakpoint-down(sm) {
        display: inline;
      }
    }
  }

  .hulu-footer {
    text-align: left;
    color: $text-contrast-gray;
  }

  .footer--row {
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    &-section {
      display: inline-block;
      margin: 0;
      padding: 0;
      @include media-breakpoint-down(sm) {
        padding-bottom: 16px;
      }
      a {
        display: block;
        padding-bottom: 8px;
      }
    }
  }

  .footer-section-container {
    @include media-breakpoint-down(md) {
      padding-bottom: 23px;
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
      border-bottom: 1px solid $gray-7;
    }
  }

  .footer-section-container-single {
    @include media-breakpoint-down(md) {
      padding-bottom: 23px;
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
    }
  }

  .footer--site-links {
    padding: 52px 0 86px;
    @include media-breakpoint-down(lg) {
      padding: 46px 0 46px;
    }
    @include media-breakpoint-down(md) {
      padding: 42px 0 0;
    }
  }

  .footer-row-expandable {
    display: flex;
    flex-wrap: wrap;
    &.footer-row-expanded {
      @include media-breakpoint-down(sm) {
        display: flex;
      }
    }
    &.footer-row-collapsed {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .footer--section-expand {
    @include media-breakpoint-down(sm) {
      display: none;
    }
    &-mobile {
      display: none;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }

  a {
    text-decoration: none;
    color: $text-contrast-gray;
  }

  span {
    color: $text-contrast-gray;
  }

  .footer--legal-links {
    border-top: 2px solid $gray-7;
    padding: $footer-line-space 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      border-top: none;
    }

    &-left {
      margin: 0 42px 32px 0;
      min-width: fit-content;
      white-space: nowrap;

      span,
      a {
        padding-right: 32px;
      }
      a:last-child {
        padding-right: 0;
      }
    }

    &-right {
      display: flex;
      flex-wrap: wrap;
      margin-left: -32px;
      margin-bottom: 32px;

      &-col {
        margin-left: 32px;
        margin-right: 0;
        box-sizing: border-box;
        min-width: fit-content;

        &:last-child {
          margin: 0;
        }

        @include media-breakpoint-down(sm) {
          &:nth-child(2) {
            margin: 0;
          }
          &:last-child {
            margin-left: 32px;
          }
        }

        .footer--legal-link {
          img {
            padding-right: 4px;
          }
        }
      }
    }
  }

  .footer--legal-link {
    display: inline-block;
  }

  .footer__icon-plus-mobile {
    @include media-breakpoint-down(sm) {
      display: inline-block;
      vertical-align: middle;
      transition: transform 0.7s ease-in-out;
      float: right;
    }

    &-open {
      @include media-breakpoint-down(sm) {
        transform: scaleY(-1);
      }
    }
  }

  h3 {
    line-height: 28px;

    @include media-breakpoint-down(sm) {
      cursor: pointer;
      padding: 32px 0;
      margin: 0;
    }

    .footer__icon-plus-mobile {
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }
  }
}
